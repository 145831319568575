import { createApp } from 'vue';
import App from './App.vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
// import './registerServiceWorker';
import vue3GoogleLogin from 'vue3-google-login'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueCryptojs from 'vue-cryptojs'

import store from './store/index'
import router from './router'
import { createGtm } from '@gtm-support/vue-gtm';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createHead } from '@unhead/vue';

const app = createApp(App)

app.use(router);
app.use(store);
app.use(autoAnimatePlugin);
app.use(VueCryptojs);
app.use(vue3GoogleLogin, {
    clientId: '523054575759-6dchpj56goc8ecnn2sshtl6f853k5tel.apps.googleusercontent.com',
});

app.use(createGtm({
    id: 'G-7DH09HEZQZ',
    vueRouter: router
}));

const head = createHead()
app.use(head);


app.mount('#app');
