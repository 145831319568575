import { createStore } from 'vuex';

interface DeveloperSettings {
    show_logger: boolean,
    show_vector: boolean,
    user_continent: string,
    api_v: string,
    enabled: boolean,
    image_count: number,
}

function developer_mode_settings() : any {
    if (localStorage.getItem("PRIME_AI_DORSIA_DEVELOPER") === null) {
        return false;
    }

    console.log(localStorage.getItem("PRIME_AI_DORSIA_DEVELOPER"));

    const dev_settings : DeveloperSettings = JSON.parse(window.localStorage.getItem('PRIME_AI_DORSIA_DEVELOPER') || '');
    console.log(dev_settings);
    return dev_settings;
}

function what_device_am_i(): 'mobile' | 'tablet' | 'desktop' {
    const screen_width: number = window.innerWidth;
    if (screen_width <= 767) {
        return 'mobile';
    }
    if (screen_width >= 768 && screen_width <= 991) {
        return 'tablet';
    }
    return 'desktop';
}


export interface Filter {
    id: number;
    name: string;
    selected: boolean;
    hidden: boolean;
}

export enum Pages {
    INTRO = 'intro',
    LOADING = 'loading',
    NOTHING_FOUND = 'nothing_found',
    LOOKALIKE = 'lookalike',
    ERROR = 'error',
}


const State = {
    display_login_prompt  : false,
    display_payment_prompt: false,
    display_success_prompt: false,
    display_dropdown_login_prompt: false,
    user_wants_to_upgrade: false,

    selected_gender: 0,

    filters: {
        colors: [] as Filter[],
        categories: [] as Filter[],
        brand: [] as Filter[],
    },

    user_details: {
        logged_in: false,
        email: '',
        platform: '',
        platform_id: '',
        notifications: [],
        sql_id: 0,
        raw_response: {},
        name: '',
        url_pic: '',
        google_response: {},
        subscribed: false,
    },
    countries: require('../data/country.json'),
    user_location_data: {
        country_code: '',
        continent: '',
        lookup: null as any
    },

    DEVELOPER_MODE: false,
    DEVELOPER_MODE_SETTINGS: developer_mode_settings(),
    global_scope: true,
    users_uploaded_img_url: '',
    uploaded_image_id: 0,
    image_url: null,
    prime_id: process.env.VUE_APP_RETAILER_ID,
    retailer_shopify_domain: process.env.VUE_APP_RETAILER_ID,
    device: what_device_am_i(),
    user_login_details: {
        logged_in: false,
        email: '',
        platform: '',
        platform_id: '',
        sql_id: 0,
        raw_response: {},
        name: '',
        url_pic: '',
        google_response: {},
    },
    share_url: '',
    RGB_current: '0,0,0',
    display_intro_page: true,
    user_subscribed_to_events: false,
    display_loading_page: false,
    display_error_page: false,
    nothing_found_page: false,
    display_look_a_like_page: false,
    privacy_policy_accepted: false,
    item_identification: '',
    notifications_set: new Set(),
    product_header_display: {
        title: true,
        price: false,
        brand: false,
    },
    image_feed: [] as any[],
    error_page_msg: '',
    page_showing: Pages.INTRO,
    loading_spinner: false,
}

export type State = typeof State;

export default createStore<State>({

    state: State,
    mutations: {
        set_uploaded_image_id(state: State, id: number) {
            state.uploaded_image_id = id;
        },

        display_or_hide_upgrade_modal_prompt(state: State, n: boolean){
            state.user_wants_to_upgrade = n;
        },

        display_or_hide_login_prompt(state: State, n: boolean) {
            state.display_login_prompt = n;
        },

        display_or_hide_dropdown_login_prompt(state: State, n: boolean) {
            state.display_dropdown_login_prompt = n;
        },

        display_or_hide_payment_prompt(state: State, n: boolean) {
            state.display_payment_prompt = n;
        },

        display_or_hide_success_prompt(state: State, n: boolean) {
            state.display_success_prompt = n;
        },

        update_notifications_on_user(state: State, n: any){
            state.user_details.notifications = n;
        },

        update_user_details(state: State, n: any){
            state.user_details = n;
        },

        update_selected_gender(state: State, n: number) {
            state.selected_gender = n;
        },

        set_filters(state: State, filters: typeof State.filters) {
            state.filters = filters;
        },

        set_user_data_from_location(state: State, ip_data: any) {
            try {
                if (!ip_data) {
                    throw new Error('Location Error');
                }
                state.user_location_data = state.countries[ip_data.country_code];
                // Special logic, we decide the continent.
                switch (ip_data.country_code) {
                case 'GB':
                    state.user_location_data.continent = 'GB';
                    break;
                default:
                    state.user_location_data = state.countries[ip_data.country_code];
                    break;
                }
                // DeveloperMode - Override the values.
                if (state.DEVELOPER_MODE && state.DEVELOPER_MODE_SETTINGS) {
                    state.user_location_data.continent =
                        state.DEVELOPER_MODE_SETTINGS.user_continent;
                }
                if (state.user_location_data) {
                    state.user_location_data.lookup = ip_data;
                } else {
                    state.user_location_data = {
                        continent: '',
                        country_code: '',
                        lookup: null
                    };
                    state.user_location_data.lookup = '';
                }
            } catch (error) {
                console.log(error);
                state.user_location_data.continent = '';
            }
        },

        set_identification(state: State) {
            for (const item of state.filters.categories) {
                if (item.selected) {
                    state.item_identification = item.name;
                }
            }
            return state.item_identification = '10';
        },
        
        set_image_feed(state: State, payload: { feed: any[], path: string } ) {
            state.image_feed = payload.feed;
            state.share_url = payload.path || state.share_url;
            state.page_showing = Pages.LOOKALIKE;
        },

        set_image_url(state: State, url: string) {
            state.users_uploaded_img_url = url;
        },

        sort_feed(state: State, sort: any) {
            const sort_val: string = sort.target.value;
            state.product_header_display.title = false;
            state.product_header_display.brand = false;
            state.product_header_display.price = false;

            switch (sort_val) {
            case 'low_high_price':
                state.image_feed.sort((a, b) => {
                    return a.price - b.price;
                });
                state.product_header_display.price = true;
                break;
            case 'high_low_price':
                state.image_feed.sort((a, b) => {
                    return b.price - a.price;
                });
                state.product_header_display.price = true;
                break;
            case 'accuracy':
                state.image_feed.sort((a: any, b: any) => {
                    return a.score - b.score;
                });
                state.product_header_display.title = true;
                break;
            case 'A-Z':
                state.image_feed.sort((a: any, b: any) => {
                    return a.brand.localeCompare(b.brand);
                });
                state.product_header_display.brand = true;
                break;
            case 'Z-A':
                state.image_feed.sort((a: any, b: any) => {
                    return b.brand.localeCompare(a.brand);
                });
                state.product_header_display.brand = true;
                break;
            default:
                break;
            }
        },

        show_page(state: State, page: Pages) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            state.page_showing = page;
        },

        show_error(state: State, message: string) {
            state.page_showing = Pages.ERROR;
            state.error_page_msg = message;
        },

        set_loading_spinner(state: State, showing: boolean) {
            state.loading_spinner = showing;
        }
    },
    getters: {
        selected_filters(state) {
            console.log(state.filters);
            const result = Object.fromEntries(
                Object.entries(state.filters).map(([key, value]) => [
                    key,
                    value.filter(filter => filter.selected).map(filter => filter.id)
                ])
            );
            console.log('result',  result);
            return result;
        },

    },
    actions: {
        async update_filters({ state, getters, commit }) {
            try {
                const selected_filters = getters.selected_filters;
                console.log(selected_filters);
                selected_filters.brand.pop();
                // commit('set_loading_spinner', true);
                commit('show_page', Pages.LOADING);

                const url = `https://api.primeai5.co.uk/dorsia/${process.env.VUE_APP_AOP2_API_VERSION}/uploader_service/re_run_image.php`;

                const formData = new FormData();
                formData.append('prime_id', state.prime_id);
                formData.append('retailer_shopify_domain', state.retailer_shopify_domain);
                formData.append('device', state.device);
                formData.append('development', state.DEVELOPER_MODE.toString());
                formData.append('dev_settings', state.DEVELOPER_MODE ? JSON.stringify(getters.developer_mode_settings) : '');
                formData.append('fr_api_version', process.env.VUE_APP_FR_API_VERSION);
                formData.append('user_continent', state.user_location_data.continent);
                formData.append('retailer', process.env.VUE_APP_RETAILER_ID);
                formData.append('image_id', state.uploaded_image_id.toString());
                formData.append('category', JSON.stringify(selected_filters.categories));
                formData.append('colors', JSON.stringify(selected_filters.colors));
                formData.append('gender', state.selected_gender.toString());
                formData.append('filter_mode', JSON.stringify(true));

                const response = await fetch(url, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: formData
                });

                const data = await response.json();
                const feed = data?.response?.response_object?.new_feed;
                commit('show_page', Pages.LOOKALIKE);

                if (feed.length) {
                    commit('set_image_feed', { feed: feed, url: state.users_uploaded_img_url });
                } else {
                    
                    commit('show_page', Pages.NOTHING_FOUND);
                }
            } catch (error) {
                console.error(error);
                commit('show_error', 'We didn\'t get back what was expected');
            }
        },

        async get_filters({ state, commit }): Promise<any> {
            try {
                // commit('set_loading_spinner', true);
                commit('show_page', Pages.LOADING);
                const response = await fetch(
                    `${process.env.VUE_APP_AOP2_API_URL}/aop2_get_retailers_filters.php?product=${state.uploaded_image_id}`,
                    {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify({
                            request: { device: 'NA' },
                            product_identifier: state.uploaded_image_id,
                            retailer_details: {
                                retailer_url: process.env.VUE_APP_RETAILER_ID,
                                hostname: process.env.VUE_APP_RETAILER_ID,
                                shopify_name: process.env.VUE_APP_RETAILER_ID,
                                uncleaned_shopify_store:process.env.VUE_APP_RETAILER_ID,
                                retailer_id: process.env.VUE_APP_RETAILER_ID,
                            },
                        }),
                    },
                );
                const data = await response.json();
                commit('set_identification');
                // state.item_identification = this.extract_current_category(data.categories_to_show);
                // Before we mount the filters, we need to check the retailers blacklist
                // as we don't really want to display sex toys on a child's site.
                // const filters = this.remove_blacklisted_filters(data);

                if (state.item_identification === 'sex_toys') {
                    commit('show_page', Pages.NOTHING_FOUND);
                }
                console.log('api filters', data);
                const rgb = data.RGB;
                const newFilters: typeof state.filters = {
                    brand: [],
                    colors: Object.values(data.colors),
                    categories: Object.values(data.categories_to_show)
                };
                console.log(newFilters);
                commit('set_filters', newFilters);

                // this.filters = data;
                //
                // this.RGB_current = this.filters.RGB

                // commit('set_loading_spinner', false);
                commit('show_page', Pages.LOOKALIKE);
                return data;
            } catch (error) {
                // commit('set_loading_spinner', false);
                commit('show_error', 'Something went wrong.');
                console.log(error);
                return false;
            }
        },

        async get_users_location_details({ commit }) {
            try {
                const response = await fetch(
                    `https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572`,
                );
                const ipData : Promise<object>  = await response.json();
                commit('set_user_data_from_location', ipData);

            } catch (error) {
                console.log(error);
                return false;
            }
        },

    },
    modules: {
    }
})
